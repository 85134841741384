// --production
export const SQUARE_SOURCE = {
    DEV: {
        SOURCE: "https://sandbox.web.squarecdn.com/v1/square.js",
        SQUARE_APP_ID: "sandbox-sq0idb-iggxCHRgliZn1YNBztfd9w",
        SQUARE_LOCATION_ID: "L1EDEPWSGX3QW",
    },
    PROD: {
        SOURCE: "https://web.squarecdn.com/v1/square.js",
        SQUARE_APP_ID: "sq0idp-QplKJwQE4v8SwdaOKmlXAA",
        SQUARE_LOCATION_ID: "LHKKTY2HYWJ8Y",
    },
};
export const BOOK_APPOINTMENT_URL =
    "https://squareup.com/appointments/book/3wgom89ajm97b1/LHKKTY2HYWJ8Y/start";

// This function tokenizes a payment method.
// The ‘error’ thrown from this async function denotes a failed tokenization,
// which is due to buyer error (such as an expired card). It is up to the
// developer to handle the error and provide the buyer the chance to fix
// their mistakes.
export async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
        return tokenResult.token;
    } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
            errorMessage += ` and errors: ${JSON.stringify(
                tokenResult.errors
            )}`;
        }
        throw new Error(errorMessage);
    }
}

export async function initializeCard(payments) {
    const card = await payments.card();
    card.postalCountry = "CA";
    await card.attach("#card-container");
    return card;
}
