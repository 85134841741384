import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import { Menu } from "antd";

export default function NavSection({ navConfig, ...other }) {
    const history = useNavigate();
    const onClick = (e) => {
        let route = "";
        if (e.keyPath.length > 1) {
            route = navConfig
                .find((x) => x.title === e.keyPath[1])
                .children.find((y) => y.title === e.keyPath[0]).path;
        } else {
            route = navConfig.find((x) => x.title === e.keyPath[0]).path;
        }
        history(route);
    };
    return (
        <Menu className="navMen" mode="inline" onClick={onClick}>
            {navConfig.map((item) => {
                if (item.children) {
                    return (
                        <Menu.SubMenu
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        >
                            {item.children.map((menu) => (
                                <Menu.Item
                                    key={menu.title}
                                    to={menu.path}
                                    component={RouterLink}
                                >
                                    {menu.title}
                                </Menu.Item>
                            ))}
                        </Menu.SubMenu>
                    );
                } else
                    return (
                        <Menu.Item
                            key={item.title}
                            to={item.path}
                            component={RouterLink}
                            icon={item.icon}
                        >
                            {item.title}
                        </Menu.Item>
                    );
            })}
        </Menu>
    );
}
