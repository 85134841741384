export const addressLocation = () => (
  <>
    <h5 className="address1">Unit 268, 8128 128 St</h5>
    <p className="address2">Payal Business Centre</p>
    <p className="address2">Surrey, BC</p>
    <p className="address2">V3W 1R1</p>
  </>
);
export const addressPhone = () => (
  <>
    <h5
      style={{
        fontSize: "1.2em",
        fontWeight: 800,
      }}
    >
      Call or WhatsApp
    </h5>
    <p
      style={{
        fontSize: "1.2rem",
        color: "rgba(124,139,161,1)",
      }}
    >
      +1-778-986-2021
    </p>
    <p
      style={{
        fontSize: "1.2rem",
        color: "rgba(124,139,161,1)",
      }}
    >
      +1-778-970-0007
    </p>
    <p
      style={{
        fontSize: "1.2rem",
        color: "rgba(124,139,161,1)",
      }}
    >
      Fax: +1-778-404-1755
    </p>
  </>
);
export const addressEmail = () => (
  <>
    <h5
      style={{
        fontSize: "1.2em",
        fontWeight: 800,
      }}
    >
      robin@aivc.ca
    </h5>
    <p
      style={{
        fontSize: "1.2rem",
        color: "rgba(124,139,161,1)",
      }}
    >
      Payal Business Centre
    </p>
    <p
      style={{
        fontSize: "1.2rem",
        color: "rgba(124,139,161,1)",
      }}
    >
      Surrey, BC
    </p>
    <p
      style={{
        fontSize: "1.2rem",
        color: "rgba(124,139,161,1)",
      }}
    >
      V3W 1R1
    </p>
  </>
);
