import { Avatar, Col, List, Row } from "antd";
import { PAGE_SECTION } from "src/constants/pageSection";
export default function Expertise() {
    return (
        <Row className="expertise-row">
            <Col xs={24} sm={24} md={24} lg={13} xl={13}>
                <div>
                    <h5 className="highlight-stats">OUR EXPERTISE</h5>
                    <h1 className="header-text">
                        We have the most &nbsp;
                        <span className="highlightText">professional</span>
                        &nbsp; marketing team.
                    </h1>
                    <p className="description-stats">
                        Take a consultation and let us show you how we are
                        different. You wont be disappointed. Serving the
                        industry since 2017. Held multiple seminars in the past
                        years through different organizations.
                    </p>
                </div>
                <div>
                    <List
                        itemLayout="horizontal"
                        dataSource={PAGE_SECTION.MAIN.EXPERTISE.expertiseList}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.avatar} />}
                                    title={item.title}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </Col>
            <Col xs={24} sm={24} md={0} lg={11} xl={11}>
                <img
                    className="expertise-cover"
                    src="https://res.cloudinary.com/asset-aivc/image/upload/v1662678317/stats_t0xu2q.png"
                    alt="expertise list"
                ></img>
            </Col>
        </Row>
    );
}
