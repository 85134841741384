import { ArrowRightOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { flipInX } from "react-animations";
import { Link } from "react-router-dom";
import { AppButton } from "src/common";
import Navbar from "src/components/Header/Navbar";
import styled, { keyframes } from "styled-components";
const bounceAnimation = (animate) => keyframes`${animate}`;

const ZoomInDiv = styled.div`
    animation: 1s ${bounceAnimation(flipInX)};
`;
export default function Cover() {
    return (
        <>
            <div className="wrapper">
                <Navbar isService isHome />
                <video loop autoPlay muted>
                    <source src="static/home.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="isMobile">
                <Header
                    style={{
                        position: "fixed",
                        zIndex: 1,
                        width: "100%",
                        padding: 0,
                    }}
                >
                    <Navbar isService isHome fixedHeader />
                </Header>
                <Content>
                    <Row className="cover-row ">
                        <div className="cover-col  cover-landing">
                            <ZoomInDiv>
                                <h1 className="header-text cover-header">
                                    IMMIGRATION
                                    <div className="textCenter cover-header">
                                        &
                                    </div>
                                    VISA CONSULTATION
                                </h1>
                                <h1 className="sub-text">
                                    Visit | Work | Study | Sponsor
                                </h1>
                                <p className="category-highlight">
                                    Experienced | Knowledgeable | Serving
                                    Industry since 2017
                                </p>
                            </ZoomInDiv>
                            <Link to="/services">
                                <AppButton
                                    isCover
                                    css="cover-btn"
                                    type="primary"
                                    title="Discover More"
                                    icon={<ArrowRightOutlined />}
                                />
                            </Link>
                        </div>
                    </Row>
                </Content>
            </div>
        </>
    );
}
