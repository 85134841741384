import { Content } from "antd/lib/layout/layout";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { TITLE_LINK } from "src/constants/pageSection";
import Contact from "src/views/pages/MainBody/Contact";
import Cover from "src/views/pages/MainBody/Cover";
import Expertise from "src/views/pages/MainBody/Expertise";
import Services from "src/views/pages/MainBody/ProfessionalServices";
import Stats from "src/views/pages/MainBody/Stats";
import StepsManual from "src/views/pages/MainBody/StepsManual";
import Testimonials from "src/views/pages/MainBody/Testimonials";
import Footer from "../Footer/FooterPageContent";
import Page from "../Page";
import "./Home.scss";

function Home() {
    return (
        <>
            <Page title={TITLE_LINK.HOME}>
                <div className="home landing-page isMobileHome">
                    <Content>
                        <Cover />
                        <AnimationOnScroll
                            animateOnce
                            animateIn="animate__fadeInLeftBig"
                        >
                            <Stats />
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateOnce
                            animateIn="animate__fadeInRightBig"
                        >
                            <Services />
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateOnce
                            animateIn="animate__fadeInDownBig"
                        >
                            <Expertise />
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateOnce
                            animateIn="animate__fadeInUpBig"
                        >
                            <StepsManual />
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateOnce
                            animateIn="animate__fadeInLeftBig"
                        >
                            <Testimonials />
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateOnce
                            animateIn="animate__fadeInUp"
                        >
                            <Contact />
                        </AnimationOnScroll>
                    </Content>
                </div>
            </Page>
            <Footer />
        </>
    );
}

export default Home;
