import { styled } from "@mui/material/styles";
import { Col, Row } from "antd";
import { Link, Link as RouterLink } from "react-router-dom";
import { LOGO_PATH_BLUE, TITLE_LINK } from "src/constants/pageSection";
import Page from "../Page";
import RegisterForm from "./register/RegisterForm";

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
        color: "#594ae3",
    },
}));

export default function Register() {
    return (
        <RootStyle title={TITLE_LINK.REGISTER}>
            <Row className="login-page">
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={10}
                    xl={10}
                    className="login-col"
                >
                    <div className="register-page-cover">
                        <Link to="/" className="navbar-logo">
                            <img
                                src={LOGO_PATH_BLUE}
                                alt="logo"
                                className="img-logo login-header-logo"
                            />
                        </Link>
                        <div className="register-page">
                            <h1 className="login-header">Sign Up</h1>
                            <RegisterForm />
                            <div className="login-subText">
                                Already have an account? &nbsp;
                                <Link
                                    variant="subtitle2"
                                    component={RouterLink}
                                    to="/login"
                                    underline="hover"
                                    className="link"
                                >
                                    Sign In
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={0}
                    lg={14}
                    xl={14}
                    className="login-cover "
                >
                    <img
                        className="login-img register"
                        src="https://res.cloudinary.com/asset-aivc/image/upload/v1659566417/signup-illustration_vdrger.svg"
                        alt="Register Page"
                    ></img>
                </Col>
            </Row>
        </RootStyle>
    );
}
