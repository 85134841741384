export const clientInfoDoc = [
    {
        title: "clientExcel",
        label: "Upload the filled Client information Detail (Excel sheet)",
        supportFile: "Supported File Type: *.xlsx max 4MB",
        acceptedFileType: [
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
        isMandatory: true,
    },
];
export const passportUploadDocsList = [
    {
        title: "clientUOP",
        label: "Upload the filled and hand signed Form 5476 (use of representative)",
        supportFile: "Supported File Type: *.xlsx max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "clientAOR",
        label: "Upload the filled and hand signed Form 5475 (authority to release info)",
        supportFile: "Supported File Type: *.xlsx max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "passport",
        label: "Current Passport and Previous Passport if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
];
export const commonDocsList = [
    {
        title: "marriage",
        label: "Marriage Certificate",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "travel",
        label: "Travel History",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "educ",
        label: "Educational Documents",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "work",
        label: "Work History",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "refusal",
        label: "Refusal Letter, if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const familyDocsList = [
    {
        title: "ritual",
        label: "Marriage and other Ritual Pics",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "gift",
        label: "Gifts Receipt sent to each other",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "receipt",
        label: "Money transfer receipts (if sent to each other)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "chat",
        label: "Chat history",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "phone",
        label: "Phone Call history",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "paystub",
        label: "Job Letter and Pay Slips",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "noa",
        label: "NOA and T4, if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "bank",
        label: "Bank Statements",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "assess",
        label: "Property Assessment Papers, if applicable",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const fswDocsList = [
    {
        title: "employer",
        label: "Full Address of the Employer (CRA Registered)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "refusal",
        label: "Refusal Letter, if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "relative",
        label: "Canadian Relative Info, if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "job",
        label: "Employment Letter, Promotion letter, Salary revision letter, Deputation/transfer letter, Resign letter etc.",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "ielts",
        label: "IELTS details",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const pnpDocsList = [
    {
        title: "birth",
        label: "Birth certificates of each family member(Spouse & Children)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "child",
        label: "Children information",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const pnpCecCommon = [
    {
        title: "relative",
        label: "Canadian Relative Info, if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "job",
        label: "Employment Letter, Promotion letter, Salary revision letter, Deputation/transfer letter, Resign letter etc.",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "study",
        label: "Study permit / Work permit (All)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "ielts",
        label: "IELTS details",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const cecDocsList = [
    {
        title: "employer",
        label: "Full Address of the Employer (CRA Registered)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "lmia",
        label: "LMIA details if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const sponsorFSWDocsList = [
    {
        title: "payStub",
        label: "Pay stubs (12 months)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "noa",
        label: "NOA and T4, if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "fundsProof",
        label: "Proof of Funds, Bank Statements etc",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "rcmp",
        label: "Police Clearance Certificate. BLS + RCMP",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "medical",
        label: "Medical check report",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "workPart",
        label: "Part time Work experience full details if any,",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const picFileUploadTitle = [
    {
        title: "photo",
        label: "Digital Photo",
        supportFile: "Click to follow the specification for digital photo",
        acceptedFileType: ["image/jpeg", "image/png", "image/jpg"],
        isMandatory: true,
    },
];
export const filesDocumentUploadTitle = passportUploadDocsList.concat(
    clientInfoDoc,
    commonDocsList,
    familyDocsList,
    fswDocsList,
    pnpDocsList,
    pnpCecCommon,
    cecDocsList,
    sponsorFSWDocsList
);
