import { combineReducers } from "redux";

import category from "./category";
import notes from "./note";
import posts from "./posts";
import status from "./status";

export const reducers = combineReducers({
    posts,
    notes,
    status,
    category,
});
