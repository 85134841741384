import { PlusOutlined } from "@ant-design/icons";
import { Card, Container, Stack } from "@mui/material";
import { Button, Table, Typography } from "antd";
import "antd/dist/antd.min.css";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { TITLE_LINK } from "src/constants/pageSection.js";
import * as api from "../../../api/index.js";
import Page from "../../Page";
import "../CaseFiles.scss";
import NoteModal from "../NoteModal.js";
import { expandedRowRender, tableColumns } from "../tableCol.js";

export default function CEC() {
    const user = JSON.parse(localStorage.getItem("profile"));
    const [data, setDataSource] = useState(null);
    const [loading, setLoading] = useState(null);
    const [fileCategory, setFileCategory] = useState(null);
    const [postId, setPostId] = useState(null);
    const [postData, setPostData] = useState({
        historicalNotes: [],
    });
    const [openDialog, setOpenDialog] = useState(false);
    const isMounted = useRef(true);
    useEffect(() => {
        fetchData();
        return () => {
            isMounted.current = false;
        };
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const result = await api.getPostByFileCategory("CEC");
        let myData = result.data;
        let clientName = tableColumns.find((col) => col.key === "clientName");

        let action = tableColumns.find((col) => col.key === "action");

        const nameFilters = myData.map((element) => {
            if (element.hasOwnProperty("clientName") === true) {
                element = {
                    text: element.clientName,
                    value: element.clientName,
                };
            }
            return element;
        });
        clientName.filters = nameFilters.filter((item) => item !== undefined);
        if (action) {
            action.render = (_, record) => (
                <Button type="primary" onClick={() => handleClickOpen(record)}>
                    <PlusOutlined />
                    Note
                </Button>
            );
        }
        setDataSource(myData);
        setLoading(false);
    };
    const handleClickOpen = (selectedRecord) => {
        setPostId(selectedRecord._id);
        setFileCategory(selectedRecord.category);
        if (selectedRecord.historicalNotes.length) {
            postData.historicalNotes = selectedRecord.historicalNotes;
        }
        setOpenDialog(true);
    };
    const onFinish = async (value) => {
        postData.category = fileCategory;
        postData?.historicalNotes.push({
            noteSubject: value.noteSubject,
            note: value.note,
            creator: user.userName,
            createdAt: new Date().toISOString(),
        });

        let newPost = await api.updatePost(postId, {
            ...postData,
            name: user?.userEmail,
        });
        if (newPost) {
            handleClose();
            fetchData();
        }
    };
    const handleClose = () => {
        setOpenDialog(false);
    };
    const { Title } = Typography;
    return (
        <Page title={TITLE_LINK.CEC_DASHBOARD}>
            <Container>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={3}
                >
                    <Title level={2}>CEC Files</Title>
                    <Link to="/pricing">
                        <Button type="primary">
                            <PlusOutlined />
                            Apply New File
                        </Button>
                    </Link>
                </Stack>
                <Card>
                    <Table
                        rowKey="_id"
                        loading={loading}
                        columns={tableColumns}
                        dataSource={data}
                        expandable={{
                            expandedRowRender,
                        }}
                    />
                </Card>
                {openDialog && (
                    <NoteModal
                        openDialog={openDialog}
                        handleClose={handleClose}
                        onFinish={onFinish}
                    />
                )}
            </Container>
        </Page>
    );
}
