export const testimonials = [
    {
        name: "Akash Singh",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "BEST and LEGIT place to get any kind of consultation regarding IRCC (pls beware of fake and duplicate consultants who totally screwed my whole future for just their experiments in the past!!!!) then after searching 95% of DUPLICATE consultants got contact with ORIGINAL and LICENSED team the IMMIGRATION AND VISA CONSULTANCY discussed my whole case with managing director ROBIN KAPOOR they guided me with the proper path to my permanent residency all credit goes to team the specially to their helpful staff BISHAN G and MANISH G are very friendly and situation understanding staff don't have words to praise them they all are like family to me now always ready for any kind of help with their knowledgeable experience HIGHLY RECOMMEND to everyone thank you soo much team the",
    },
    {
        name: "Sumanyu Sharma",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Great Service! the immigration team is so helpful and their service is very fast. They are just a phone call away. They helped us very professionally throughout my PR process. Their fee is also very reasonable. Robin Kapoor & Bishan are very supportive and always willing to help.I want to thank ApplyPR team to help us to make our dream come true.  I highly recommend their service. They are best in Surrey.",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
    {
        name: "Jaspreet Kaur Dhillon",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "They are exceptional. I knew i was in great hands the moment i walked through their doors. I felt comfortable sharing my concerns and felt listened to throughout the process. Mr. Robin kapoor and Bishan are passionate and very service oriented. I highly recommend the immigration!!",
    },
    {
        name: "Gurjyot Sethi",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "Had an amazing experience with the Immigration. My agent quite messed up with my spouse's file, but the was there to guide me through it. Robin and Bishan were always there, regularly in touch over the phone in case of any doubts. I would totally recommend the Immigration for any visa procedures.",
    },
    {
        name: "Aaisha Raiyyani",
        title: "BEST and LEGIT place to get any kind of consultation",
        description:
            "This is the BEST in town guys! Robin Kapoor is amazing !! I have had 3 files so far and all of them were APPROVED They treat your applications like it’s their own Super helpful in every step of the process, they give you the best service possible, answer all your questions and concerns with patience and super comfortable to talk to too. One of the team mates, Bishan, is also a rockstar. He is very patient and always pretty quick with his response to any questions! Worth investing time and money !!!",
    },
];
