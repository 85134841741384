import * as Yup from "yup";
const { message, notification } = require("antd");
export const NotificationMessage = {
    successLogin: () => {
        message.success("Login successful. Welcome to ApplyPR !");
    },
    errorLogin: () => {
        message.error("Please check your credentials and login again !", 5);
    },
    errorRegister: () => {
        message.error("Sorry ! Something went wrong. Please try again !", 5);
    },
};
export const openNotificationWithIcon = (type, placement) => {
    notification[type]({
        message: "Agreement Signed",
        description: "Thank You!",
        placement,
    });
};
export const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),
    password: Yup.string().required("Password is required"),
});
export const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("First name required"),
    lastName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Last name required"),
    email: Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),
    password: Yup.string().required("Password is required"),
});
export const clearStorage = () => {
    localStorage.removeItem("isLoginUser");
    localStorage.removeItem("profile");
    localStorage.removeItem("fileCategory");
    localStorage.removeItem("newFilePostID");
    localStorage.clear();
};
export const ACCOUNT_POPOVER_NAV_MENU = [
    {
        label: "Home",
        icon: "eva:home-fill",
        linkTo: "/",
    },
    {
        label: "Profile",
        icon: "eva:person-fill",
        linkTo: "#",
    },
    {
        label: "Settings",
        icon: "eva:settings-2-fill",
        linkTo: "#",
    },
];
