import { LoginOutlined } from "@ant-design/icons";
import { LoadingButton } from "@mui/lab";

import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as api from "../../../api/index.js";

import { LoginSchema, NotificationMessage } from "src/common/LoginConfig.js";
import { getRoutePath } from "src/common/values.js";
import IconEye from "src/components/IconEye.js";
import "./LoginForm.css";

export default function LoginForm() {
    const history = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            // remember: true
        },
        validationSchema: LoginSchema,
        onSubmit: async () => {
            try {
                const userData = await api.signIn(values);
                const result = userData?.data?.result;
                const userType = result.userType;
                const userName = result.name;
                const userEmail = result.email;
                const userToken = userData?.data?.token;
                const profile = { userName, userEmail, userToken, userType };
                localStorage.setItem("profile", JSON.stringify(profile));
                history(getRoutePath(userEmail, userType));
            } catch (error) {
                console.log(error);
                NotificationMessage.errorLogin();
            }
        },
    });
    const {
        errors,
        touched,
        values,
        isSubmitting,
        handleSubmit,
        getFieldProps,
    } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        size="medium"
                        label="Email address"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        size="medium"
                        {...getFieldProps("password")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleShowPassword}
                                        edge="end"
                                    >
                                        <IconEye
                                            icon={
                                                showPassword
                                                    ? "eva:eye-fill"
                                                    : "eva:eye-off-fill"
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ my: 2 }}
                >
                    {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

                    {/* <Link variant="subtitle2" to="/forgot-password" underline="hover">
            Forgot password?
          </Link> */}
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    <LoginOutlined /> &nbsp;Login
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
}
