import { OFFICE_AGENT, ROUTE_URL } from "src/common/values";
import {
    cecDocsList,
    clientInfoDoc,
    commonDocsList,
    familyDocsList,
    fswDocsList,
    passportUploadDocsList,
    picFileUploadTitle,
    pnpCecCommon,
    pnpDocsList,
    sponsorFSWDocsList,
} from "./uploadDocuments/commonFilesToUpload";

export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const FETCH_ALL = "FETCH_ALL";
export const LIKE = "LIKE";
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const CREATE_NOTE = "CREATE_NOTE";
export const FETCH_ALL_NOTE = "FETCH_ALL_NOTE";
export const FETCH_COUNT = "FETCH_COUNT";
export const FETCH_CATEGORY_COUNT = "FETCH_CATEGORY_COUNT";

export const eCategory = {
    FSW: {
        subtitle: "Federal Skilled Worker Visa Application",
        title: "FSW",
        description: "Apply for  FSW Application",
        fee: "$235.00",
        ROUTE_URL: "/" + ROUTE_URL.FSWVisa,
        checklist: {
            Applicant: {
                title: "Applicant - PRE ITA",
                docs: [
                    "Passport of Applicant",
                    "Marriage Certificate",
                    "Travel History",
                    "Educational Documents",
                    "Work History",
                    "Full Address of the Employer (CRA Registered)",
                    "Form 5476 (use of representative)",
                    "Form 5475 (authority to release info)",
                    "Refusal Letter, if any",
                    "Client information Detail (Excel sheet)",
                    "Canadian Relative Info, if any",
                    "Employment Letter, Promotion letter, Salary revision letter, Deputation/transfer letter, Resign letter etc.",
                    "IELTS details",
                ],
            },
            Sponsor: {
                title: "Applicant - POST ITA",
                docs: [
                    "Pay stubs (12 months)",
                    "NOA and T4, if any",
                    "Proof of Funds, Bank Statements etc",
                    "Police Clearance Certificate. BLS + RCMP",
                    "Medical check report",
                    "Digital Pic (50mm x 70mm)",
                    "Part time Work experience full details if any,",
                ],
            },
        },
        docsUpload: clientInfoDoc.concat(
            passportUploadDocsList,
            commonDocsList,
            fswDocsList
        ),
        picsUpload: picFileUploadTitle,
        sponsorDocs: sponsorFSWDocsList,
    },
    PNP: {
        subtitle: "Provincial Nominee Program Application",
        title: "PNP",
        description: "Apply for  PNP Application",
        fee: "$235.00",
        ROUTE_URL: "/" + ROUTE_URL.PNPVisa,
        checklist: {
            Applicant: {
                title: "Applicant",
                docs: [
                    "Passport of Applicant",
                    "Marriage Certificate",
                    "Travel History",
                    "Educational Documents",
                    "Work History",
                    "Form 5476 (use of representative)",
                    "Form 5475 (authority to release info)",
                    "Refusal Letter, if any",
                    "Client information Detail (Excel sheet)",
                    "Canadian Relative Info, if any",
                    "Employment Letter, Promotion letter, Salary revision letter, Deputation/transfer letter, Resign letter etc.",
                    "Study permit / Work permit (All)",
                    "IELTS details",
                    "Pay stubs (12 months)",
                    "NOA and T4, if any",
                    "Proof of Funds, Bank Statements etc",
                    "Police Clearance Certificate. BLS + RCMP",
                    "Medical check report",
                    "Digital Pic of each family member(Spouse & Children)",
                    "Part time Work experience full details if any,",
                    "Birth certificates of each family member(Spouse & Children)",
                    "Children information",
                ],
            },
            Sponsor: {
                title: "If minor:",
                docs: [],
            },
        },
        docsUpload: clientInfoDoc.concat(
            passportUploadDocsList,
            commonDocsList,
            pnpCecCommon,
            pnpDocsList,
            sponsorFSWDocsList
        ),
        picsUpload: picFileUploadTitle,
    },
    Family: {
        subtitle: "Family Sponsorship Application",
        title: "Family",
        description: "Apply for Family Sponsorship Application",
        fee: "$749.00",
        ROUTE_URL: "/" + ROUTE_URL.FamilyVisa,
        checklist: {
            Applicant: {
                title: "Applicant",
                docs: [
                    "Passport of Applicant",
                    "Marriage Certificate",
                    "Travel History",
                    "Marriage and other Ritual Pics",
                    "Gifts Receipt sent to each other",
                    "Money transfer receipts (if sent to each other)",
                    "Chat history",
                    "Phone Call history",
                    "Educational Documents",
                    "Work History",
                    "Job Letter and Pay Slips",
                    "NOA and T4, if any",
                    "Bank Statements",
                    "Property Assessment Papers, if applicable",
                    "Digital Pic",
                    "Refusal Letter, if any",
                    "Form 5476 (use of representative)",
                    "Form 5475 (authority to release info)",
                ],
            },
            Sponsor: {
                title: "Sponsor",
                docs: [
                    "Passport of Sponsor",
                    "Canadian Status Document (work or study permit)",
                    "Enrollment letter, College offer letter",
                    "College official transcripts",
                    "Employment Letter",
                    "3 Recent Pay Slips",
                    "Bank Statement",
                    "Other educational documents",
                    "All T4(s), if applicable",
                    "6 reference letter notarised",
                    "Rent agreement with joint names",
                ],
            },
        },
        docsUpload: clientInfoDoc.concat(
            passportUploadDocsList,
            commonDocsList,
            familyDocsList
        ),
        picsUpload: picFileUploadTitle,
    },

    CEC: {
        subtitle: "Canadian Experience Class Application",
        title: "CEC",
        description: "Apply for  CEC Application",
        fee: "$149.00",
        ROUTE_URL: "/" + ROUTE_URL.CECVisa,
        checklist: {
            Applicant: {
                title: "Applicant -  PRE ITA",
                docs: [
                    "Passport of Applicant",
                    "Marriage Certificate",
                    "Travel History",
                    "Educational Documents",
                    "Work History",
                    "Full Address of the Employer (CRA Registered)",
                    "Form 5476 (use of representative)",
                    "Form 5475 (authority to release info)",
                    "Refusal Letter, if any",
                    "Client information Detail (Excel sheet)",
                    "Canadian Relative Info, if any",
                    "Employment Letter, Promotion letter, Salary revision letter, Deputation/transfer letter, Resign letter etc.",
                    "Study permit / Work permit (All)",
                    "LMIA details if any",
                    "IELTS details",
                ],
            },
            Sponsor: {
                title: "Applicant -  POST ITA",
                docs: [
                    "Pay stubs (12 months)",
                    "NOA and T4, if any",
                    "Proof of Funds, Bank Statements etc",
                    "Police Clearance Certificate. BLS + RCMP",
                    "Medical check report",
                    "Digital Pic (50mmx70mm)",
                    "Part time Work experience full details if any,",
                ],
            },
        },
        docsUpload: clientInfoDoc.concat(
            passportUploadDocsList,
            commonDocsList,
            pnpCecCommon,
            cecDocsList
        ),
        picsUpload: picFileUploadTitle,
        sponsorDocs: sponsorFSWDocsList,
    },
    CEC_PNP: {
        title: "CEC+PNP",
        description: "Apply for New CEC and PNP Application",
    },
};

export const PROGRAM_DETAILS = [
    {
        title: "Federal Skilled Worker",
        imgSrc: "/static/icons/lmia.png",
        key: "new_visit",
        description: `Do you want to :<ul><li>Immigrate permanently to Canada?</li><li>Have skilled jobs and work expertise?</li>
    <li>Reunite with your family members?</li>
    <li>Have foreign work experience?</li></ul>`,
        fileType: {
            cost: eCategory.FSW.fee,
            routePath: eCategory.FSW.ROUTE_URL,
        },
    },
    {
        title: "Family Sponsorship Application",
        imgSrc: "/static/icons/visit.png",
        key: "family_sponsor",
        description: `Do you want to :<ul><li>Sponsor your parents or grandparents or relatives or others to immigrate in Canada?</li><li>Sponsor your adopted child or dependent children?</li>
    <li>Do you want to reunite with your family members?</li>
   </ul>`,
        fileType: {
            cost: eCategory.Family.fee,
            routePath: eCategory.Family.ROUTE_URL,
        },
    },
    {
        title: "Canadian Experience Class (CEC)",
        imgSrc: "/static/icons/extend.png",
        key: "family_sponsor",
        description: `Do you want to :<ul><li>Become permanent residents in Canada?</li><li>Have work experience in Canada?</li>
   
   </ul>`,
        fileType: {
            cost: eCategory.CEC.fee,
            routePath: eCategory.CEC.ROUTE_URL,
        },
    },
    {
        title: "Provincial Nominee Class (PNP)",
        imgSrc: "/static/icons/extend_visit.png",
        key: "new_study",
        description: `Do you want to :<ul><li>Stay in a particular province in Canada?</li>
    <li>Have skills and experience adding value to Canada?</li><li>Aim for permanent residency in Canada</li>
    <li>Know how to immigrate by getting nominated by province or territory?</li></ul>`,
        fileType: {
            cost: eCategory.PNP.fee,
            routePath: eCategory.PNP.ROUTE_URL,
        },
    },
];

export const PROFESSIONAL_SERVICE = [
    {
        title: "Provincial Nominee Program (PNP)",
        imgSrc: "/static/icons/extend_visit.png",
        key: "visit",
        description: `<ul class="pro-service"><li>Workers with skills and work experience available in Canada</li>
    <li>Settle in a province or territory</li><li>Become permanent resident</li></ul>`,
    },
    {
        title: "Canadian Experience Class (CEC)",
        imgSrc: "/static/icons/extend.png",
        key: "study",
        description: `<ul class="pro-service"><li>Have work experience in Canada</li>
    <li>Become a permanent resident under Canadian Experience Class</li></ul>`,
    },
    {
        title: "Sponsorship Application",
        imgSrc: "/static/icons/visit.png",
        key: "sponsor",
        description: `<ul class="pro-service"><li>Sponsorship application assessment</li>
    <li>Sponsor child or other dependent</li><li>Sponsor grandparent or parents</li> <li>Reunite with family members.</li></ul>`,
    },
    {
        title: "Federal Skilled Worker",
        imgSrc: "/static/icons/lmia.png",
        key: "spousal",
        description: `<ul class="pro-service"><li>Skilled professional expertise</li>
    <li>Foreign work experience</li> <li>Become permanent resident in Canada</li></ul>`,
    },
];

export const eFileStatus = {
    Retained: "Retained",
    VerifyPending: "Files Pending for Verification",
    MissingDocsNotify: "Awaiting for Missing Documents",
    UploadedMissingDocs: "Received Missing Documents",
    FollowUp: "Follow Up",
    FileSubmitted: "File Submitted",
};
export const officeAssigneeList = ["Robin", "Bishan", "Monish", "Juli"];

export const uploadProps = {
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange({ file, fileList }) {
        if (file.status !== "uploading") {
            console.log(file, fileList);
        }
    },
    defaultFileList: [],
};

export function getFileAssignee(category) {
    let assignee = "";
    switch (category) {
        case eCategory.Family:
        case eCategory.CEC:
        case eCategory.PNP:
        case eCategory.FSW:
        case eCategory.CEC_PNP:
            assignee = OFFICE_AGENT.ASSISTANT.NAME;
            break;
        default:
            assignee = "";
    }
    return assignee;
}
export const visitPurposeList = [
    { text: "Business" },
    { text: "Tourism" },
    { text: "Study" },
    { text: "Family Visit" },
    { text: "Other" },
];

export const workPermitType = [
    { key: "open", value: "Open Work Permit" },
    { key: "lmia", value: "LMIA" },
];
