import { MessageFilled } from "@ant-design/icons";
export const text = "na";
export const immigrationInfo = [
    {
        key: "visit",
        title: "Provincial Nominee Class (PNP)",
        description: (
            <>
                <strong>
                    If you want to settle in a particular province or territory
                    in Canada, if you have work and skills in demand in Canada,
                    and aiming to be nominated by a province in Canada to
                    immigrate.
                </strong>
                <div>Contact us now so we can assist you further.</div>
            </>
        ),
    },
    {
        key: "study",
        title: "Canadian Experience Class (CEC)",
        description: (
            <>
                <strong>
                    If you have direct work experience within Canada, if you are
                    planning to become permanent resident in Canada.
                </strong>
                <div>Contact us now so we can assist you further.</div>
            </>
        ),
    },
    {
        key: "sponsor",
        title: "Sponsorship - Family / Spouse / Relatives",
        description: (
            <>
                <strong>
                    Sponsor your loved ones through this fantastic program made
                    available to reunite families. Sponsor your spouse /
                    dependent children or parents and grandparents or your
                    relatives.
                </strong>
                <div>Contact us now so we can assist you further.</div>
            </>
        ),
    },
    {
        key: "family",
        title: "Federal Skilled Worker",
        description: (
            <>
                <strong>
                    If you want to immigrate to Canada based on foreign work
                    experience. If you are planning to become permanent resident
                    in Canada.
                </strong>
                <div>Contact us now so we can assist you further.</div>
            </>
        ),
    },
    // {
    //   key: "lmia",
    //   title: "LMIA Stream (Business Professionals/Senior Manager Opportunities)",
    //   description: (
    //     <>
    //       <strong>Who is an Owner Operator?</strong>
    //       <div>
    //         As the name proposes, Owner-Operator, is a term used to define a
    //         foreign national who owns the controlling interest (50.1%) in the
    //         business, in simple words – operate the business they own.
    //       </div>
    //     </>
    //   ),
    // },
];
export const immigrationForumInfo = [
    {
        key: "express-entry",
        title: "Immigration to Canada",
    },
    {
        key: "study",
        title: "Temporary Entry to Canada",
    },
    {
        key: "family",
        title: "Working in Canada",
    },
];

export const forumData = [
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
];
// Array.from({
//   length: 23,
// }).map((_, i) => ({
//   href: "https://ant.design",
//   title: `ant design part ${i}`,
//   avatar: "https://joeschmoe.io/api/v1/random",
//   description:
//     "Ant Design, a design language for background applications, is refined by Ant UED Team.",
//   content:
//     "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
// }));
