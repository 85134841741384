import { UserAddOutlined } from "@ant-design/icons";
import { LoadingButton } from "@mui/lab";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as api from "../../../api/index.js";

import { NotificationMessage, RegisterSchema } from "src/common/LoginConfig.js";
import { USER_TYPE } from "src/common/values.js";
import IconEye from "src/components/IconEye.js";

export default function RegisterForm() {
    const [showPassword, setShowPassword] = useState(false);
    const history = useNavigate();
    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            userType: "",
        },
        validationSchema: RegisterSchema,
        onSubmit: async () => {
            try {
                let userData = await api.signUp(values);
                const result = userData?.data?.result;
                const userType = result.userType;
                const userName = result.name;
                const userEmail = result.email;
                const userToken = userData?.data?.token;
                const profile = { userName, userEmail, userToken, userType };
                localStorage.setItem("profile", JSON.stringify(profile));
                localStorage.setItem("isLoginUser", "true");
                history("/pricing");
            } catch (error) {
                console.log(error);
                NotificationMessage.errorRegister();
            }
        },
    });
    const {
        errors,
        touched,
        values,
        isSubmitting,
        handleSubmit,
        getFieldProps,
    } = formik;
    const [value, setValue] = useState("");
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <FormControl>
                        <FormLabel>
                            Select User Type
                            <RadioGroup
                                row
                                name="row-radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                                {...getFieldProps("userType")}
                            >
                                <FormControlLabel
                                    value={USER_TYPE.APPLICANT}
                                    control={<Radio />}
                                    label={USER_TYPE.APPLICANT}
                                />
                                <FormControlLabel
                                    value={USER_TYPE.AGENT}
                                    control={<Radio />}
                                    label={USER_TYPE.AGENT}
                                />
                            </RadioGroup>
                        </FormLabel>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="First name"
                        {...getFieldProps("firstName")}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                    />
                    <TextField
                        fullWidth
                        label="Last name"
                        {...getFieldProps("lastName")}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                    />
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Email address"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />
                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        {...getFieldProps("password")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={() =>
                                            setShowPassword((prev) => !prev)
                                        }
                                    >
                                        <IconEye
                                            icon={
                                                showPassword
                                                    ? "eva:eye-fill"
                                                    : "eva:eye-off-fill"
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        <UserAddOutlined /> &nbsp; Register
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
