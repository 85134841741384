import { Button, Table } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

export const tableColumns = [
    {
        title: "Client Name",
        dataIndex: "clientName",
        key: "clientName",
        filters: [],
        filterMode: "tree",
        filterSearch: true,
        render: (text, record) => (
            <Link to={`/file/${record._id}`}>
                <Button style={{ padding: 0 }} type="link">
                    {text}
                </Button>
            </Link>
        ),
        sorter: (a, b) =>
            String(a.clientName).localeCompare(String(b.clientName)),
    },
    {
        title: "Category",
        dataIndex: "category",
        key: "category",
        sorter: (a, b) => String(a.category).localeCompare(String(b.category)),
    },
    {
        title: "Created On",
        dataIndex: "createdAt",
        key: "createdAt",
        responsive: ["md"],
        render: (record) => `${new Date(record).toDateString()}`,
        sorter: (a, b) =>
            moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
        title: "File Status",
        dataIndex: "fileStatus",
        key: "fileStatus",
        sorter: (a, b) =>
            String(a.fileStatus).localeCompare(String(b.fileStatus)),
    },
    {
        title: "Assigned To",
        dataIndex: "assignee",
        key: "assignee",
        responsive: ["md"],
        sorter: (a, b) => String(a.assignee).localeCompare(String(b.assignee)),
    },
    {
        title: "Action",
        dataIndex: "operation",
        key: "action",
    },
];

export const nestedColumns = [
    {
        title: "Created On",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record) => `${new Date(record).toDateString()}`,
    },
    {
        title: "Created By",
        dataIndex: "creator",
        key: "creator",
    },
    {
        title: "Subject",
        dataIndex: "noteSubject",
        key: "noteSubject",
    },
    {
        title: "Note",
        dataIndex: "note",
        key: "note",
        render(text) {
            return {
                props: {
                    style: { overflowWrap: "anywhere" },
                },
                children: <div>{text}</div>,
            };
        },
    },
];
export const expandedRowRender = (record) => {
    return (
        <Table
            style={{ paddingLeft: "1em" }}
            rowKey="_id"
            size="small"
            columns={nestedColumns}
            dataSource={record.historicalNotes}
            pagination={false}
        />
    );
};
