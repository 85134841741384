import { styled } from "@mui/material/styles";
import { Col, Row } from "antd";
import { Link, Link as RouterLink } from "react-router-dom";
import {
    ACTION_LINK,
    LOGO_PATH_BLUE,
    TITLE_LINK,
} from "src/constants/pageSection";
import Page from "../Page";
import LoginForm from "./login/LoginForm";
export const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

export default function Login() {
    return (
        <RootStyle title={TITLE_LINK.LOGIN}>
            <Row className="login-page">
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={10}
                    xl={10}
                    className="login-col"
                >
                    <div className="login-page-cover">
                        <Link to="/" className="navbar-logo">
                            <img
                                src={LOGO_PATH_BLUE}
                                alt="logo"
                                className="img-logo login-header-logo"
                            />
                        </Link>
                        <div>
                            <h1 className="login-header">Sign In</h1>
                            <LoginForm />
                            <div className="login-subText">
                                Don’t have an account? &nbsp;
                                <Link
                                    variant="subtitle2"
                                    component={RouterLink}
                                    to="/register"
                                    underline="hover"
                                    className="link"
                                >
                                    {ACTION_LINK.PROGRAMS.title}
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={0}
                    lg={14}
                    xl={14}
                    className="login-cover "
                >
                    <img
                        className="login-img"
                        src="https://res.cloudinary.com/asset-aivc/image/upload/v1659566417/login-illustration_yqh3l9.svg"
                        alt="Login Page"
                    ></img>
                </Col>
            </Row>
        </RootStyle>
    );
}
