import { Form, Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";

export default function NoteModal({ ...props }) {
    const [form] = Form.useForm();
    return (
        <Modal
            title="Add Note"
            visible={props.openDialog}
            onOk={form.submit}
            onCancel={props.handleClose}
        >
            <Form form={form} layout="vertical" onFinish={props.onFinish}>
                <Form.Item
                    name="noteSubject"
                    rules={[
                        {
                            required: true,
                            message: "Please enter subject",
                        },
                    ]}
                >
                    <Input placeholder="Enter Subject" />
                </Form.Item>
                <Form.Item
                    name="note"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your note!",
                        },
                    ]}
                >
                    <TextArea rows={4} placeholder="Additional Note" />
                </Form.Item>
            </Form>
        </Modal>
    );
}
