import { useState } from "react";
import { AppLink } from "src/common";
import { NAV_MENU } from "src/common/values";

export default function ClientNavMenu({ scrollNav, home }) {
    const [click, setClick] = useState(false);
    const closeMobileMenu = () => setClick(false);
    return (
        <>
            {NAV_MENU.map((item, index) => (
                <AppLink
                    scrollNav={scrollNav}
                    home={home}
                    key={index}
                    to={`/${item.value}`}
                    css="nav-links"
                    click={closeMobileMenu}
                    title={item.name}
                />
            ))}
        </>
    );
}
