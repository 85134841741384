import { Alert, Button, Checkbox, Form, Input } from "antd";
import { useState } from "react";
import { eFileStatus } from "src/constants/actionTypes.js";
import * as api from "../../api/index.js";
import "./TermsAgreement.scss";

export default function TermsAgreement({ postData, sendDataToParent }) {
    const [formDisable, setFormDisable] = useState(null);
    const fetchDataAction = async () => {
        let newPost = await api.createPost({ ...postData });
        if (newPost) {
            localStorage.setItem("newFilePostID", newPost.data._id);
            sendDataToParent(postData);
            setFormDisable(true);
        }
    };
    const [formClient] = Form.useForm();
    const onFinish = (values) => {
        postData.clientName = values.firstName + " " + values.lastName;
        postData.fileStatus = eFileStatus.Retained;
        postData.retainerDetails = values;
        fetchDataAction(postData);
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 6,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            span: 10,
            offset: 6,
        },
    };
    const onReset = () => {
        formClient.resetFields();
    };
    return (
        <Form
            className="terms-form"
            {...formItemLayout}
            form={formClient}
            name="control-hooks"
            onFinish={onFinish}
        >
            <Form.Item
                name="firstName"
                label="FIRST NAME:"
                rules={[
                    {
                        required: true,
                        message: "Please enter your first name",
                    },
                ]}
            >
                <Input disabled={formDisable} />
            </Form.Item>
            <Form.Item
                name="lastName"
                label="LAST NAME:"
                rules={[
                    {
                        required: true,
                        message: "Please enter your last name",
                    },
                ]}
            >
                <Input disabled={formDisable} />
            </Form.Item>
            <Form.Item
                name="address"
                label="ADDRESS:"
                rules={[
                    {
                        required: true,
                        message: "Please enter your current address",
                    },
                ]}
            >
                <Input disabled={formDisable} />
            </Form.Item>
            <Form.Item
                name="phone"
                label="CLIENT PHONE:"
                rules={[
                    {
                        required: true,
                        message: "Please select your phone number",
                    },
                ]}
            >
                <Input disabled={formDisable} />
            </Form.Item>
            <Form.Item
                name="email"
                label="CLIENT EMAIL ADDRESS:"
                rules={[
                    {
                        type: "email",
                        required: true,
                        message: "Please enter valid email address",
                    },
                ]}
            >
                <Input disabled={formDisable} />
            </Form.Item>
            <Form.Item
                {...tailFormItemLayout}
                name="signedAgreement"
                valuePropName="checked"
                rules={[
                    {
                        required: true,
                        message:
                            "Please confirm you have agreed to our retainer agreement",
                    },
                ]}
            >
                <Checkbox>
                    I have read and accepted the &nbsp;
                    <a
                        target="_blank"
                        rel="noReferrer"
                        href="https://res.cloudinary.com/asset-aivc/image/upload/v1659678900/RetainerTemplate_uibq1j.pdf"
                    >
                        agreement
                    </a>
                </Checkbox>
            </Form.Item>
            <Form.Item className="submitBtn" label="Submit">
                <Button type="primary" htmlType="submit" disabled={formDisable}>
                    Confirm
                </Button>
                <Button
                    htmlType="button"
                    onClick={onReset}
                    disabled={formDisable}
                >
                    Reset
                </Button>
            </Form.Item>
            {formDisable && (
                <Alert
                    style={{ margin: "1em 0", borderRadius: "10px" }}
                    message="Continue Next Step"
                    description="Thank you for retaining us to process your application. You may proceed Next to complete the steps!"
                    type="success"
                    showIcon
                />
            )}
        </Form>
    );
}
