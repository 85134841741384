import decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AppButton, AppLink } from "src/common";
import { clearStorage } from "src/common/LoginConfig";
import { getRoutePath } from "src/common/values";
import { LOGO_PATH, LOGO_PATH_BLUE } from "src/constants/pageSection";
import ClientNavMenu from "./ClientNavMenu";
import "./Navbar.scss";

export const isLogin = () =>
    localStorage.getItem("isLoginUser")
        ? JSON.parse(localStorage.getItem("isLoginUser").toLowerCase())
        : false;

function Navbar({ ...props }) {
    const isLoginUser = isLogin();
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem("profile"))
    );
    const history = useNavigate();
    const dispatch = useDispatch();
    const [scrollNav, setScrollNav] = useState(false);
    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", changeNav);
        const token = user?.userToken;
        showButton();
        if (token) {
            const decodedToken = decode(token);
            if (decodedToken.exp * 1000 < new Date().getTime()) logOut();
        }
    }, [history, user?.userToken]);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const logOut = () => {
        dispatch({ type: "LOGOUT" });
        clearStorage();
        setUser(null);
        history("/");
    };
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    const triggerLogOut = () => {
        logOut();
        closeMobileMenu();
    };
    window.addEventListener("resize", showButton);

    return (
        <nav
            className={
                scrollNav && props.isHome
                    ? "navbar  unScroll "
                    : !scrollNav && props.isHome
                    ? "navbar coverNav"
                    : "navbar home"
            }
        >
            <div className="navbar-container">
                <Link
                    to="/"
                    className={
                        props.fixedHeader
                            ? "navbar-logo mobileTop"
                            : "navbar-logo"
                    }
                    onClick={closeMobileMenu}
                >
                    <img
                        src={
                            !scrollNav && props.isHome
                                ? LOGO_PATH
                                : LOGO_PATH_BLUE
                        }
                        alt="logo"
                        className="img-logo"
                    />
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? "fas fa-times" : "fas fa-bars"} />
                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <ClientNavMenu scrollNav={scrollNav} home={props.isHome} />
                    {/* <Button
                        type="link"
                        target="_blank"
                        rel="noReferrer"
                        style={{ lineHeight: "inherit", fontSize: "inherit" }}
                        href={BOOK_APPOINTMENT_URL}
                    >
                        Book Appointment
                    </Button> */}
                    {/* {user === null ? (
                        <>
                            <AppLink
                                home={props.isHome}
                                scrollNav={scrollNav}
                                css="nav-links"
                                to="/register"
                                click={closeMobileMenu}
                                title={
                                    <AppButton
                                        type="primary"
                                        css="signUp"
                                        title="
                Sign Up"
                                    />
                                }
                            />
                            <AppLink
                                home={props.isHome}
                                scrollNav={scrollNav}
                                type="link"
                                to="/login"
                                css="nav-links"
                                click={closeMobileMenu}
                                title="Login"
                            />
                        </>
                    ) : (
                        <AppLink
                            home={props.isHome}
                            scrollNav={scrollNav}
                            to={getRoutePath(user.userEmail, user.userType)}
                            css="nav-links"
                            title="Account"
                        />
                    )}
                    {user !== null && (
                        <AppLink
                            home={props.isHome}
                            scrollNav={scrollNav}
                            css="nav-links"
                            to="/"
                            click={triggerLogOut}
                            title="Logout"
                        />
                    )} */}
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
