import React from "react";
import CEC from "./components/Dashboard/CEC/CEC";
import ClientFileDetails from "./components/Dashboard/PNP_FSW/ClientFileDetails";
import FSW from "./components/Dashboard/PNP_FSW/FSW";
import PNP from "./components/Dashboard/PNP_FSW/PNP";
const PNPFile = React.lazy(() => import("./views/pages/StepsPages/PNPFile"));
const FSWFile = React.lazy(() => import("./views/pages/StepsPages/FSWFile"));
const CECFile = React.lazy(() => import("./views/pages/StepsPages/CECFile"));
const FamilyFile = React.lazy(() =>
    import("./views/pages/StepsPages/FamilyFile")
);
const AdminDashboard = React.lazy(() => import("./components/Dashboard"));
const FollowUp = React.lazy(() =>
    import("./components/Dashboard/FollowUp/FollowUp")
);
export const AgentApplication = React.lazy(() =>
    import("./components/AgentAccount/AgentApplication")
);
const PendingDocuments = React.lazy(() =>
    import("./components/ClientAccount/MissingDocs")
);
const Family = React.lazy(() =>
    import("./components/Dashboard/Sponsor/Family")
);
export const routes = [
    {
        path: "pnp-file",
        component: <PNPFile />,
    },
    {
        path: "fsw-file",
        component: <FSWFile />,
    },
    {
        path: "cec-file",
        component: <CECFile />,
    },
    {
        path: "family-file",
        component: <FamilyFile />,
    },
    {
        path: "/file/:id",
        component: <AgentApplication />,
    },
];
export const dashboardRoutes = [
    {
        path: "/dashboard/main",
        component: <AdminDashboard />,
    },
    {
        path: "/dashboard/casePNP",
        component: <PNP />,
    },
    {
        path: "/dashboard/caseFSW",
        component: <FSW />,
    },
    {
        path: "/dashboard/caseCEC",
        component: <CEC />,
    },
    {
        path: "/dashboard/caseFamily",
        component: <Family />,
    },
    {
        path: "/dashboard/FollowUp",
        component: <FollowUp />,
    },
    {
        path: "/dashboard/client",
        component: <ClientFileDetails />,
    },
    {
        path: "/dashboard/client/:id",
        component: <PendingDocuments />,
    },
];
