import { Container, Stack } from "@mui/material";
import { Alert, Card, Col, Form, Row, Table, Timeline, Typography } from "antd";
import "antd/dist/antd.min.css";
import TextArea from "antd/lib/input/TextArea.js";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { AppButton, getETagColor, headerDetail } from "src/common/index.js";
import { eFileStatus } from "src/constants/actionTypes.js";
import { PAGE_SECTION, TITLE_LINK } from "src/constants/pageSection.js";
import * as api from "../../../api/index.js";
import Page from "../../Page";
import "../CaseFiles.scss";
export const tableColumns = [
    {
        title: "Client Name",
        dataIndex: "clientName",
        key: "clientName",
        filters: [],
        filterMode: "tree",
        filterSearch: true,

        sorter: (a, b) =>
            String(a.clientName).localeCompare(String(b.clientName)),
    },
    {
        title: "Category",
        dataIndex: "category",
        key: "category",
        sorter: (a, b) => String(a.category).localeCompare(String(b.category)),
    },
    {
        title: "Created On",
        dataIndex: "createdAt",
        key: "createdAt",
        responsive: ["md"],
        render: (record) => `${new Date(record).toDateString()}`,
        sorter: (a, b) =>
            moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
        title: "File Status",
        dataIndex: "fileStatus",
        key: "fileStatus",
        sorter: (a, b) =>
            String(a.fileStatus).localeCompare(String(b.fileStatus)),
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        responsive: ["md"],
        sorter: (a, b) => String(a.email).localeCompare(String(b.email)),
    },
];
export default function ClientFileDetails() {
    const user = JSON.parse(localStorage.getItem("profile"));
    const [data, setDataSource] = useState(null);
    const [loading, setLoading] = useState(null);
    const isMounted = useRef(true);
    useEffect(() => {
        fetchData();
        return () => {
            isMounted.current = false;
        };
    }, []);
    const fetchData = async () => {
        setLoading(true);
        const result = await api.getPostByUserEmail(user.userEmail);
        let myData = result.data.detail;
        let clientName = tableColumns.find((col) => col.key === "clientName");

        const nameFilters = myData.map((element) => {
            if (element.hasOwnProperty("clientName") === true) {
                element = {
                    text: element.clientName,
                    value: element.clientName,
                };
            }
            return element;
        });
        clientName.filters = nameFilters.filter((item) => item !== undefined);
        setDataSource(myData);
        setLoading(false);
    };
    const [userFollowUpMessage, showUserFollowUpMessage] = useState(false);
    const onFinish = async (customerQuery) => {
        customerQuery.Email = user.userEmail;
        customerQuery.Name = user.userName;
        const newFollowUp = await api.followUpCustomerWithMessage(
            customerQuery
        );
        if (newFollowUp.statusText === "Created") {
            showUserFollowUpMessage(true);
        }
    };
    const { Title } = Typography;
    return (
        <Page title={TITLE_LINK.DASHBOARD}>
            <Container>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={3}
                >
                    <Title level={2}>View Your Application</Title>
                </Stack>
                <Card>
                    <Table
                        rowKey="_id"
                        loading={loading}
                        columns={tableColumns}
                        dataSource={data}
                        expandable={{
                            expandedRowRender: (record) => (
                                <p
                                    style={{
                                        margin: 0,
                                    }}
                                >
                                    <Card
                                        key={record._id}
                                        title={headerDetail(record)}
                                        className="applicant-detail"
                                        style={{ borderRadius: "20px" }}
                                    ></Card>
                                </p>
                            ),
                        }}
                    />
                </Card>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Card title="Application Timeline Process Stages">
                            <Timeline>
                                {Object.keys(eFileStatus).map((item, index) => (
                                    <Timeline.Item
                                        color={getETagColor(eFileStatus[item])}
                                        key={index}
                                    >
                                        {eFileStatus[item]}
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Card
                            title="Reach out to us for any query"
                            className="card-div"
                        >
                            <Form
                                layout="vertical"
                                className="contact-home-form"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="Message"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your query!",
                                        },
                                    ]}
                                >
                                    <TextArea
                                        rows={4}
                                        placeholder="Your Message Here"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <AppButton
                                        type="primary"
                                        css="cover-btn"
                                        htmlType="submit"
                                        block
                                        title="SEND"
                                    />
                                </Form.Item>
                            </Form>
                            {userFollowUpMessage && (
                                <div>
                                    <Alert
                                        message={
                                            PAGE_SECTION.CONTACT.acknowledge
                                        }
                                        description={
                                            PAGE_SECTION.CONTACT
                                                .mailSuccessMessage
                                        }
                                        type="success"
                                    />
                                </div>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}
