import CECEligibility from "src/components/StepForm/EligibilityCriteriaApplication/CECEligibility";
import FamilyEligibility from "src/components/StepForm/EligibilityCriteriaApplication/FamilyEligibility";
import FSWEligibility from "src/components/StepForm/EligibilityCriteriaApplication/FSWEligibility";
import PNPEligibility from "src/components/StepForm/EligibilityCriteriaApplication/PNPEligibility";
import SquarePayment from "src/components/StepForm/SquarePayment";
import TermsAgreement from "src/components/StepForm/TermsAgreement";
import UploadDocs from "src/components/StepForm/UploadDocs/UploadDocs";
import { eCategory } from "../constants/actionTypes";

export const steps = [
    "Basic Info",
    "Retainer Agreement",
    "Make Payment",
    "Upload Documents",
];
export const categoryEligibilityContent = (
    postData,
    category,
    sendDataToParent
) => {
    switch (category) {
        case eCategory.FSW:
            return (
                <FSWEligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case eCategory.PNP:
            return (
                <PNPEligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case eCategory.Family:
            return (
                <FamilyEligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case eCategory.CEC:
            return (
                <CECEligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        default:
            return <></>;
    }
};

export function getActiveStepContent(
    activeStep,
    category,
    postData,
    sendDataToParent
) {
    switch (activeStep) {
        case 0:
            return categoryEligibilityContent(
                postData,
                category,
                sendDataToParent
            );
        case 1:
            return (
                <TermsAgreement
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case 2:
            return (
                <SquarePayment
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                    category={category}
                />
            );
        case 3:
            return (
                <UploadDocs
                    category={category}
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );

        default:
            return "Unknown step";
    }
}
