import { Alert, Col, Form, Input, Row } from "antd";
import { useState } from "react";
import { AppButton } from "src/common";
import { ACTION_LINK, PAGE_SECTION } from "src/constants/pageSection";
import * as api from "../../../api/index.js";

export default function Contact() {
  const [userFollowUpMessage, showUserFollowUpMessage] = useState(false);
  const onFinish = async (userEmail) => {
    const newFollowUp = await api.followUpCustomer(userEmail);
    if (newFollowUp.statusText === "Created") {
      showUserFollowUpMessage(true);
    }
  };
  return (
    <div className="cover-row contact-row-home">
      <h5 className="highlight-stats textCenter">CONTACT US</h5>
      <h1 className="header-text textCenter">
        Feel free to &nbsp;
        <span className="highlightText">get in touch</span>
        &nbsp; with us.
      </h1>
      <Row>
        <Col xs={24} sm={24} md={17} lg={12} xl={12}>
          <img
            src="https://res.cloudinary.com/asset-aivc/image/upload/v1659566437/email-illustration_hltzws.svg"
            alt="contact Page"
          ></img>
        </Col>
        <Col xs={24} sm={24} md={7} lg={12} xl={12}>
          <div className="contact-home">
            <p className="description-stats contact-desc">
              Consult us now for PR Assessment and get your fees adjusted
              towards any immigration application applied through us. Absolute
              Free consultation if you retain us in future. We are located in
              the heart of Surrey (Payal Business Centre) and serving clients
              from Kelowna, Kamloops, Vancouver, Abbotsford, Brampton, Toronto,
              etc.
            </p>
            {!userFollowUpMessage && (
              <Form
                layout="inline"
                className="contact-home-form"
                onFinish={onFinish}
              >
                <Form.Item
                  className="emailContact"
                  name="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                    { type: "email" },
                  ]}
                >
                  <Input size="large" placeholder="Your Email Address" />
                </Form.Item>
                <Form.Item>
                  <AppButton
                    type="primary"
                    css="cover-btn noMargin"
                    htmlType="submit"
                    title={ACTION_LINK.CONTACT.title}
                  />
                </Form.Item>
              </Form>
            )}
            {userFollowUpMessage && (
              <div>
                <Alert
                  message={PAGE_SECTION.CONTACT.acknowledge}
                  description={PAGE_SECTION.CONTACT.mailSuccessMessage}
                  type="success"
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
