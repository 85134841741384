import { Col, Row } from "antd";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import { AppButton, AppLayout } from "src/common";
import FooterPageContent from "src/components/Footer/FooterPageContent";
import Page from "src/components/Page";
import { ACTION_LINK, TITLE_LINK } from "src/constants/pageSection";
import "./AboutUs.css";
import CompanyTeam from "./CompanyTeam";
import CompanyValues from "./CompanyValues";
export default function AboutUs() {
    return (
        <AppLayout
            css={{ marginTop: "5em" }}
            content={
                <>
                    <Page title={TITLE_LINK.ABOUT}>
                        <AnimationOnScroll
                            animateOnce
                            animateIn="animate__fadeInLeftBig"
                        >
                            <Row className="about">
                                <Col xs={24} sm={24} md={12} lg={12} xl={13}>
                                    <div className="about-title">
                                        <h5 className="highlight-stats">
                                            ABOUT US
                                        </h5>
                                        <h1 className="header-text">
                                            We Offer Only The Best
                                        </h1>
                                        <p className="description-about">
                                            ApplyPR is a renowned and licensed
                                            Immigration Consulting Firm located
                                            at Payal Business Centre in
                                            Surrey(Vancouver), BC, Canada
                                            offering affordable Canadian
                                            Immigration to clients across
                                            Canada. Serving our dear customers
                                            from Toronto, Brampton, Windsor,
                                            Calgary, Winnipeg, Nova Scotia,
                                            Vancouver, Kelowna, Victoria, etc.
                                            We generally help migrants to Settle
                                            in Canada by giving the correct
                                            guidance in a successful and ideal
                                            way. We work on “try us once, keep
                                            us forever policy”. Our company is
                                            constantly working on making dreams
                                            come alive for our loving clients as
                                            part of our vision deliverables.
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={11}>
                                    <img
                                        className="about-cover"
                                        src="/static/icons/about.png"
                                        alt="aboutUs"
                                    ></img>
                                </Col>
                            </Row>
                        </AnimationOnScroll>
                        <Row className="about-row">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <img
                                    className="vision-cover"
                                    src="/static/icons/vision.png"
                                    alt="aboutUs"
                                ></img>
                            </Col>
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                                className="vision-div"
                            >
                                <div>
                                    <h5 className="highlight-stats vision-highlight">
                                        OUR VISION
                                    </h5>
                                    <h1 className="header-text">
                                        Making Dreams Come Alive
                                    </h1>
                                    <p className="description-about vision-desc">
                                        ApplyPR is dedicated to helping people
                                        achieve their Canadian Immigration
                                        goals. We care about you, your family
                                        and your business / career, as if it was
                                        our very own. We prefer appointments to
                                        be able to deliver best that was
                                        promised through our consultation
                                        sessions. We develop tailored
                                        immigration pathways and realistic
                                        advise to those who are looking to
                                        immigrate to Beautiful Canada ! We
                                        dedicate time and attention to listen to
                                        your individual needs rather than opting
                                        for standard immigration practise."
                                    </p>
                                </div>
                                <Link to={ACTION_LINK.CONTACT.action}>
                                    <AppButton
                                        css="cover-btn about-page-contact"
                                        type="primary"
                                        title={ACTION_LINK.CONTACT.title}
                                    />
                                </Link>
                            </Col>
                        </Row>
                        <AnimationOnScroll
                            animateOnce
                            animateIn="animate__fadeInUp"
                        >
                            <div className="value-padding">
                                <h5 className="highlight-stats textCenter">
                                    OUR VALUES
                                </h5>
                                <h1 className="header-text textCenter">
                                    Why Choose Us?
                                </h1>
                            </div>
                            <CompanyValues />
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateOnce
                            animateIn="animate__fadeInLeftBig"
                        >
                            <CompanyTeam />
                        </AnimationOnScroll>
                    </Page>
                    <FooterPageContent />
                </>
            }
        ></AppLayout>
    );
}
