import { EnvironmentFilled, PhoneFilled } from "@ant-design/icons";
import { Alert, Col, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { AppButton, AppLayout } from "src/common";
import FooterPageContent from "src/components/Footer/FooterPageContent.js";
import Page from "src/components/Page.js";
import { addressLocation } from "src/constants/officeLocation";
import { PAGE_SECTION, TITLE_LINK } from "src/constants/pageSection";
import * as api from "../../../api/index.js";
import "./ContactUs.css";
export default function Contact() {
    const [userFollowUpMessage, showUserFollowUpMessage] = useState(false);
    const onFinish = async (customerQuery) => {
        const newFollowUp = await api.followUpCustomerWithMessage(
            customerQuery
        );
        if (newFollowUp.statusText === "Created") {
            showUserFollowUpMessage(true);
        }
    };
    return (
        <AppLayout
            css={{ marginTop: "4rem" }}
            content={
                <>
                    <Page title={TITLE_LINK.CONTACT} className="home">
                        <Row
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                            className="contact-row"
                        >
                            <Col xs={24} sm={24} md={17} lg={13} xl={12}>
                                <img
                                    className="contact-cover"
                                    src="https://res.cloudinary.com/asset-aivc/image/upload/v1662680861/contact_y4cbuc.jpg"
                                    alt="contactPage"
                                ></img>
                            </Col>
                            <Col xs={24} sm={24} md={7} lg={11} xl={12}>
                                <div>
                                    <h5 className="highlight-stats">
                                        Connect with Us
                                    </h5>
                                    <h1 className="header-text">
                                        Get in touch with us.
                                    </h1>
                                    <p className="description-contact">
                                        Absolute Free consultation if you retain
                                        us in future. We are located in the
                                        heart of Surrey (Payal Business Centre)
                                        and serving clients from Kelowna,
                                        Kamloops, Vancouver, Abbotsford,
                                        Brampton, Toronto, etc.
                                    </p>
                                    <Form
                                        layout="vertical"
                                        className="contact-form"
                                        onFinish={onFinish}
                                    >
                                        <Form.Item
                                            name="Email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter your email!",
                                                },
                                                { type: "email" },
                                            ]}
                                        >
                                            <Input
                                                size="large"
                                                placeholder="Your Email Address"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter your full name!",
                                                },
                                            ]}
                                        >
                                            <Input
                                                size="large"
                                                placeholder="Full Name"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="Message"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter your query!",
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                rows={4}
                                                placeholder="Your Message Here"
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <AppButton
                                                block
                                                type="primary"
                                                htmlType="submit"
                                                title="Send"
                                            />
                                        </Form.Item>
                                    </Form>
                                    {userFollowUpMessage && (
                                        <div>
                                            <Alert
                                                message={
                                                    PAGE_SECTION.CONTACT
                                                        .acknowledge
                                                }
                                                description={
                                                    PAGE_SECTION.CONTACT
                                                        .mailSuccessMessage
                                                }
                                                type="success"
                                            />
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div className="contact-office">
                                    <h1 className="header-text">Our Office</h1>
                                    <p className="contact-office-desc">
                                        Consult us now for checking assessment
                                        and get your fees adjusted towards any
                                        immigration application applied through
                                        us.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col
                                className="gutter-row"
                                xs={24}
                                sm={24}
                                md={7}
                                lg={7}
                                xl={7}
                            >
                                <div className="subDescription contactImg">
                                    <span style={{ border: "none" }}>
                                        <EnvironmentFilled className="values-icon" />
                                    </span>
                                    <span className="subtext">
                                        <span className="contactIconText">
                                            Address
                                        </span>
                                        <div className="service-desc">
                                            {addressLocation()}
                                        </div>
                                    </span>
                                </div>
                            </Col>
                            <Col
                                className="gutter-row"
                                xs={24}
                                sm={24}
                                md={7}
                                lg={7}
                                xl={7}
                            >
                                <iframe
                                    id="locationMap"
                                    title="officeAddress"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2609.6702685150253!2d-122.86802408449066!3d49.14988457931711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d99d29fa22f9%3A0x7f9e9cbc7bee61e1!2s8128%20128%20St%2C%20Surrey%2C%20BC%20V3W%201R1!5e0!3m2!1sen!2sca!4v1659049402002!5m2!1sen!2sca"
                                    height="450"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </Col>

                            <Col
                                className="gutter-row"
                                xs={24}
                                sm={24}
                                md={7}
                                lg={10}
                                xl={10}
                            >
                                <div className="subDescription contactImg">
                                    <span style={{ border: "none" }}>
                                        <PhoneFilled className="values-icon" />
                                    </span>
                                    <span className="subtext">
                                        <span className="contactIconText highlight">
                                            Call or WhatsApp
                                        </span>
                                        <div className="service-desc">
                                            <div className="description-contact">
                                                <a href="tel: +1778-986-2021">
                                                    778-986-2021
                                                </a>
                                            </div>
                                            <div className="description-contact">
                                                <a href="tel: +1778-970-0007">
                                                    778-970-0007
                                                </a>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                {/* <div className="subDescription contactImg">
              <span className=" aboutImgSpan" style={{ border: "none" }}>
                <MailFilled className="values-icon" />
              </span>
              <span className="subtext">
                <span className="contactIconText">Email</span>
                <div className="service-desc">
                  <h5 className="highlight-stats">
                    <a href="mailto: robin@aivc.ca">robin@aivc.ca</a>
                  </h5>
                </div>
              </span>
            </div> */}
                            </Col>
                        </Row>
                    </Page>
                    <FooterPageContent />
                </>
            }
        />
    );
}
