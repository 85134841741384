import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import Avatar from "react-avatar";
import { PAGE_SECTION } from "src/constants/pageSection";

export default function CompanyTeam() {
    return (
        <Row>
            <Col>
                <h5 className="highlight-stats  textCenter">OUR &nbsp; TEAM</h5>
                <h1 className="header-text textCenter">
                    Meet These Fine Folks
                </h1>
                <p className="team-desc">
                    We ensure to make circumstances manageable and provide
                    better options to provide a seamless experience for you.
                </p>
                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                    className="teams-col"
                >
                    {PAGE_SECTION.TEAMS.teamMembers.map((member, index) => (
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} key={index}>
                            <Card
                                className="team-card"
                                hoverable
                                cover={
                                    <Avatar
                                        color={Avatar.getRandomColor(
                                            "sitebase",
                                            ["red", "blue", "#594ae3"]
                                        )}
                                        round={true}
                                        style={{
                                            margin: "1em auto",
                                            display: "flex",
                                        }}
                                        name={member.name}
                                    />
                                }
                            >
                                <Meta
                                    title={member.designation}
                                    description={member.name}
                                />
                                <p className="about-agent">
                                    {member.description}
                                </p>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    );
}
