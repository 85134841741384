import { Col, Row } from "antd";
import { PAGE_SECTION } from "src/constants/pageSection";

export default function CompanyValues() {
    return (
        <Row className="value-row">
            {PAGE_SECTION.ABOUT.valuesList.map((value, index) => (
                <Col xs={24} sm={24} md={8} lg={8} xl={8} key={index}>
                    <div className="subDescription value-item">
                        <span className="service-image">{value.icon}</span>
                        <span className="valuesTitle">
                            <span className="highlight-stats">
                                {value.title}
                            </span>
                            <p className="service-desc valuesDescription">
                                {value.description}
                            </p>
                        </span>
                    </div>
                </Col>
            ))}
        </Row>
    );
}
