import {
    DASHBOARD_ROUTE_PATH,
    OFFICE_AGENT,
    ROUTE_URL,
} from "src/common/values";
import NavSection from "src/components/NavSection";
import IconEye from "../../components/IconEye";
import { ProfileOutlined, FileAddOutlined } from "@ant-design/icons";

const getIcon = (name) => <IconEye icon={name} width={22} height={22} />;

const SIDEBAR_CONFIG = {
    ADMIN: [
        // {
        //   title: "dashboard",
        //   path: DASHBOARD_ROUTE_PATH.MAIN,
        //   icon: getIcon("eva:pie-chart-2-fill"),
        // },
        {
            title: "Visit permit ",
            icon: getIcon("eva:people-fill"),
            children: [
                {
                    title: "New Visit Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "Extend Visit Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
            ],
        },
        {
            title: "Study permit ",
            path: DASHBOARD_ROUTE_PATH.STUDY,
            icon: getIcon("eva:people-fill"),
            children: [
                {
                    title: "New Study Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "Extend Study Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "PGWP",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
            ],
        },
        {
            title: "Work permit ",
            path: DASHBOARD_ROUTE_PATH.WORK,
            icon: getIcon("eva:file-text-fill"),
            children: [
                {
                    title: "New Work Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "Extend Work Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "LMIA",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
            ],
        },
        {
            title: "Sponsorship  ",
            path: DASHBOARD_ROUTE_PATH.WORK,
            icon: getIcon("eva:file-text-fill"),
            children: [
                {
                    title: "Family Sponsorship Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "Spousal Permit",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
            ],
        },
        // {
        //   title: "PR cases",
        //   path: DASHBOARD_ROUTE_PATH.PR,
        //   icon: getIcon("eva:file-text-fill"),
        // },
        {
            title: "follow up pending",
            path: DASHBOARD_ROUTE_PATH.FOLLOWUP,
            icon: getIcon("eva:people-fill"),
        },
    ],
    SUPERVISOR: [
        {
            title: "Visit permit cases",
            path: DASHBOARD_ROUTE_PATH.VISIT,
            icon: getIcon("eva:people-fill"),
            children: [
                {
                    title: "New Visit permit cases",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                    icon: getIcon("eva:people-fill"),
                },
            ],
        },
        {
            title: "Study permit cases",
            path: DASHBOARD_ROUTE_PATH.STUDY,
            icon: getIcon("eva:people-fill"),
        },
        {
            title: "work permit cases",
            path: DASHBOARD_ROUTE_PATH.WORK,
            icon: getIcon("eva:file-text-fill"),
        },
        // {
        //   title: "PR cases",
        //   path: DASHBOARD_ROUTE_PATH.PR,
        //   icon: getIcon("eva:file-text-fill"),
        // },
        {
            title: "follow up pending",
            path: DASHBOARD_ROUTE_PATH.FOLLOWUP,
            icon: getIcon("eva:people-fill"),
        },
        // {
        //   title: "Programs",
        //   path: '/'+ROUTE_URL.Programs,
        //   icon: getIcon("eva:people-fill"),
        // },
    ],
    ASSISTANT: [
        {
            title: "Visit permit ",
            icon: getIcon("eva:people-fill"),
            children: [
                {
                    title: "New Visit Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "Extend Visit Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
            ],
        },
        {
            title: "Study permit ",
            path: DASHBOARD_ROUTE_PATH.STUDY,
            icon: getIcon("eva:people-fill"),
            children: [
                {
                    title: "New Study Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "Extend Study Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "PGWP",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
            ],
        },
        {
            title: "Work permit ",
            path: DASHBOARD_ROUTE_PATH.WORK,
            icon: getIcon("eva:file-text-fill"),
            children: [
                {
                    title: "New Work Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "Extend Work Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "LMIA",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
            ],
        },
        {
            title: "Sponsorship  ",
            path: DASHBOARD_ROUTE_PATH.WORK,
            icon: getIcon("eva:file-text-fill"),
            children: [
                {
                    title: "Family Sponsorship Files",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
                {
                    title: "Spousal Permit",
                    path: DASHBOARD_ROUTE_PATH.VISIT,
                },
            ],
        },
        {
            title: "follow up pending",
            path: DASHBOARD_ROUTE_PATH.FOLLOWUP,
            icon: getIcon("eva:people-fill"),
        },
    ],
    TECH: [
        // {
        //   title: "dashboard",
        //   path: DASHBOARD_ROUTE_PATH.MAIN,
        //   icon: getIcon("eva:pie-chart-2-fill"),
        // },
        {
            title: "PNP",
            path: "/dashboard/casePNP",
            icon: getIcon("eva:globe-3-fill"),
        },
        {
            title: "FSW",
            path: "/dashboard/caseFSW",
            icon: getIcon("eva:book-fill"),
        },
        {
            title: "Sponsorship",
            path: "/dashboard/caseFamily",
            icon: getIcon("eva:file-text-fill"),
        },
        {
            title: "CEC",
            path: "/dashboard/caseCEC",
            icon: getIcon("eva:people-fill"),
        },
        {
            title: "Follow-Up",
            path: DASHBOARD_ROUTE_PATH.FOLLOWUP,
            icon: getIcon("eva:paper-plane-outline"),
        },
    ],
    APPLICANT: [
        {
            title: "File Details",
            path: DASHBOARD_ROUTE_PATH.CLIENT,
            icon: <ProfileOutlined />,
        },
        {
            title: "Add New Application",
            path: "/" + ROUTE_URL.Programs,
            icon: <FileAddOutlined />,
        },
    ],
};

export const loadSideNav = (email) => {
    switch (email) {
        case OFFICE_AGENT.ADMIN.EMAIL:
            return <NavSection navConfig={SIDEBAR_CONFIG.ADMIN} />;
        case OFFICE_AGENT.SUPERVISOR.EMAIL:
        case OFFICE_AGENT.ASSISTANT.EMAIL:
            return <NavSection navConfig={SIDEBAR_CONFIG.SUPERVISOR} />;
        case OFFICE_AGENT.TECH.EMAIL:
            return <NavSection navConfig={SIDEBAR_CONFIG.TECH} />;
        default:
            return <NavSection navConfig={SIDEBAR_CONFIG.APPLICANT} />;
    }
};

export default SIDEBAR_CONFIG;
