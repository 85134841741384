import { Layout, Spin } from "antd";
import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./components/Home/Home";
import Login from "./components/Login_Register/Login";
import Register from "./components/Login_Register/Register";
import Programs from "./components/Programs/Programs";
import ScrollToTop from "./components/ScrollToTop";
import Services from "./components/Services/Services";
import DashboardLayout from "./layouts/dashboard";
import { dashboardRoutes, routes } from "./routes";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import AboutUs from "./views/pages/MainBody/About/AboutUs";
import Contact from "./views/pages/MainBody/ContactUs";
import Page404 from "./views/pages/Page404";

export default function App() {
    return (
        <ThemeConfig>
            <ScrollToTop />
            <GlobalStyles />
            <Layout>
                <Routes>
                    <Route path="about-us" element={<AboutUs />} />
                    <Route path="services" element={<Services />} />
                    <Route path="pricing" element={<Programs />} />
                    <Route path="contact-us" element={<Contact />} />
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                    {routes.map((route) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <React.Suspense
                                    fallback={
                                        <div className="textCenter">
                                            <Spin tip="Loading..." />
                                        </div>
                                    }
                                >
                                    {route.component}
                                </React.Suspense>
                            }
                        />
                    ))}
                    <Route element={<DashboardLayout />}>
                        {dashboardRoutes.map((route) => (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={
                                    <React.Suspense
                                        fallback={
                                            <div className="textCenter">
                                                <Spin tip="Loading..." />
                                            </div>
                                        }
                                    >
                                        {route.component}
                                    </React.Suspense>
                                }
                            />
                        ))}
                    </Route>
                    <Route index element={<Home />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Layout>
        </ThemeConfig>
    );
}
