import { Button, Form, Radio, Tooltip } from "antd";
import { useState } from "react";
import { successMsg } from "src/common";
import "./Eligibility.scss";
const layout = {
    wrapperCol: {
        span: 16,
    },
};
export default function PNPEligibility({ ...props }) {
    const [form] = Form.useForm();

    const [formDisable, setFormDisable] = useState(null);
    const onFinish = (values) => {
        props.postData.basicInfoClient = values;
        props.sendDataToParent(props.postData);
        setFormDisable(true);
    };

    const onReset = () => {
        form.resetFields();
    };
    return (
        <Form
            {...layout}
            form={form}
            name="control-hooks"
            className="basicInfoClient-form"
            onFinish={onFinish}
        >
            <Form.Item
                name="location"
                label="Where are you currently located?"
                rules={[{ required: true }]}
            >
                <Radio.Group disabled={formDisable}>
                    <Radio value="Inside Canada"> Inside Canada</Radio>
                    <Radio value="Outside Canada">Outside Canada </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name="holdVisa"
                rules={[{ required: true, message: "Select an option" }]}
                label="Do you hold any Canadian visa (including valid or expired)?"
            >
                <Radio.Group disabled={formDisable}>
                    <Radio value="Yes"> Yes </Radio>
                    <Radio value="No">No</Radio>
                    <Radio value="Not Sure">Not sure</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item className="submitBtn" label="Submit">
                <Button type="primary" htmlType="submit" disabled={formDisable}>
                    Confirm
                </Button>
                <Button
                    htmlType="button"
                    onClick={onReset}
                    disabled={formDisable}
                >
                    Reset
                </Button>
            </Form.Item>
            {formDisable &&
                successMsg(
                    "Continue Next Step",
                    "Thank you for providing your inputs. You may proceed Next to read our terms and agreements to retain us for your application processing!",
                    { borderRadius: "10px" }
                )}
        </Form>
    );
}
