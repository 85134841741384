import { Col, Row, Timeline } from "antd";
import { PAGE_SECTION } from "src/constants/pageSection";

export default function StepsManual() {
    return (
        <Row>
            <Col xs={24} sm={24} md={12} lg={13} xl={13}>
                <img
                    className="steps-img"
                    src="/static/icons/steps.png"
                    alt="steps"
                ></img>
            </Col>
            <Col xs={24} sm={24} md={12} lg={11} xl={11}>
                <div className="step-manual">
                    <h5 className="highlight-stats">STEPS</h5>
                    <h1 className="header-text">
                        Easy to &nbsp;
                        <span className="highlightText">Get Started</span>
                    </h1>
                    <Timeline>
                        {PAGE_SECTION.MAIN.STEPS.steps.map((step, index) => (
                            <Timeline.Item key={index} color={step.stepColor}>
                                <h6>{step.title}</h6>
                                <p className="stepDescription">
                                    {step.description}
                                </p>
                            </Timeline.Item>
                        ))}
                    </Timeline>
                </div>
            </Col>
        </Row>
    );
}
