import { Rate } from "antd";
import Avatar from "react-avatar";
import Carousel from "react-material-ui-carousel";
import { testimonials } from "src/constants/testimonials";
export default function Testimonials() {
  return (
    <div className="testimonial">
      <div className="textCenter">
        <h5 className="highlight-stats" style={{ textAlign: "center" }}>
          TESTIMONIALS
        </h5>
        <h1 className="header-text">
          Our Clients &nbsp;
          <span className="highlightText">Love Us.</span>
        </h1>
        <p className="description-stats testimonial-stats">
          Experienced | Knowledgeable | Socially minded | Professional Expertise
          | Quality first | Consciously sourced | Google 5 star rated
        </p>
      </div>
      <Carousel autoplay={true} className="testimonial-btn">
        {testimonials.map((item, index) => (
          <div key={index} className="testimonial-card">
            <div className="testimonial-rating">
              <div className="title-testimonial">{item.title}</div>
              <p className="quote">{item.description}</p>
              <div className="testimonial-avatar">
                <Avatar
                  color={Avatar.getRandomColor("sitebase", [
                    "red",
                    "blue",
                    "#594ae3",
                  ])}
                  size="80"
                  round={true}
                  name={item.name}
                />
                <div style={{ paddingLeft: "2em" }}>
                  <Rate disabled defaultValue={5} />
                  <div className="title-testimonial">{item.name}</div>
                  <p className="googleUser">Google User</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
